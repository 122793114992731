import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Lincoln({ styles, ...props }) {
  const Elements = useComponents()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <header
        className="formkit-banner"
        style={{
          backgroundColor: styles.forElement("banner_background")
            .backgroundColor
        }}
      >
        <div
          className="formkit-banner-background"
          style={styles.forElement("banner_background")}
        />
        <div className="formkit-container">
          <Elements.Content
            className="formkit-preheader"
            name="preheader"
            defaults={{
              content: "Save the date"
            }}
          />
          <Elements.Heading
            className="formkit-heading-primary font-display"
            name="header"
            defaults={{
              content: "Learn 10 Strategies to Optimize Your Campaigns"
            }}
          />
          <Elements.Heading
            className="formkit-heading-secondary formkit-subheader"
            name="subheader"
            defaults={{
              content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec scelerisque libero, et lacinia lectus."
            }}
          />
        </div>
      </header>

      <main
        className="formkit-main"
        style={{
          backgroundColor: styles.forElement("main_background").backgroundColor
        }}
      >
        <div
          className="formkit-banner-background"
          style={styles.forElement("main_background")}
        />
        <div className="formkit-columns formkit-container">
          <Elements.Content
            className="formkit-column-content"
            name="column1"
            group="column_content"
            defaults={{
              content:
                "Pellentesque ut euismod sem. Etiam iaculis varius metus vel sodales. Fusce venenatis, elit in convallis feugiat, risus augue tempus urna, ut sodales tortor metus posuere ante. Curabitur vitae enim dignissim, vehicula odio a, maximus felis."
            }}
          />
          <Elements.Content
            className="formkit-column-content"
            name="column2"
            group="column_content"
            defaults={{
              content:
                "Pellentesque ut euismod sem. Etiam iaculis varius metus vel sodales. Fusce venenatis, elit in convallis feugiat, risus augue tempus urna, ut sodales tortor metus posuere ante. Curabitur vitae enim dignissim, vehicula odio a, maximus felis."
            }}
          />
          <Elements.Content
            className="formkit-column-content"
            name="column3"
            group="column_content"
            defaults={{
              content:
                "Pellentesque ut euismod sem. Etiam iaculis varius metus vel sodales. Fusce venenatis, elit in convallis feugiat, risus augue tempus urna, ut sodales tortor metus posuere ante. Curabitur vitae enim dignissim, vehicula odio a, maximus felis."
            }}
          />
        </div>
        <div className="formkit-container">
          <div className="formkit-content-container">
            <Elements.Heading
              className="formkit-heading-secondary font-display"
              name="content_header"
              defaults={{
                content: "Lorem ipsum dolor si"
              }}
            />
            <Elements.Region className="formkit-content" name="content">
              <Elements.Content
                defaults={{
                  content:
                    "Pellentesque ut euismod sem. Etiam iaculis varius metus vel sodales. Fusce venenatis, elit in convallis feugiat, risus augue tempus urna, ut sodales tortor metus posuere ante. Curabitur vitae enim dignissim, vehicula odio a, maximus felis. Aenean metus sapien, pulvinar quis consequat ac, mollis sit amet ipsum. In lectus enim, fringilla a nunc vel, mattis viverra tortor. Proin neque quam, iaculis sit amet dictum quis, consectetur in augue. Class aptent taciti sociosqu"
                }}
              />
            </Elements.Region>
          </div>

          <div
            className="formkit-card"
            style={styles.forElement("card_background")}
          >
            <Elements.Heading
              className="formkit-form-header font-display"
              name="form_header"
              tag="h2"
              defaults={{ content: "Sign up Today" }}
            />
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields>
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Sign up today" }}
                />
              </Elements.CustomFields>
            </Elements.Form>
            <Elements.Content
              name="disclaimer"
              defaults={{
                content: "We respect your privacy. Unsubscribe at any time."
              }}
            />
          </div>
        </div>
        <footer>
          <Elements.BuiltWith
            background="main_background"
            image="main_background"
          />
        </footer>
      </main>
    </Elements.LandingPage>
  )
}

Lincoln.style = "landing_page"
Lincoln.categories = ["Event", "Newsletter", "Product", "Waitlist", "Webinar"]
Lincoln.thumbnail = ""
Lincoln.preview = "https://demo.ck.page/lincoln"
Lincoln.fields = [
  {
    name: "first_name",
    label: "Your name"
  },
  {
    name: "email_address",
    label: "Your email address"
  }
]

Lincoln.beta = true

export default createTemplate(Lincoln, { name: "Lincoln" })
